<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getPurchaseClientList" method="post">
            <div class="con_top">
                <h2><span>■</span>B2B 수납관리 - {{name}}</h2>
                <div class="btns mb-30">
                    <a class="btn_new2 pointer" @click="register()">B2B 수납등록</a>
                </div>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <select v-model="idxCrmCenter" @change="getPurchaseClientList()" class="float-left font-16 w-120px h-40px">
                            <option value="-1">센터선택</option>
                            <option value="0">본원센터</option>
                            <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                        </select>
                        <div class="float-left dp-block mb-10">
                            <input type="text" v-model="value" class="w-200px h-40px pd-00 pl-20" placeholder="고객명/고객코드">
                            <a @click="getPurchaseClientList()" class="btn_search ml-10 pointer">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody>
                            <tr>
                                <th>센터</th>
                                <th>고객</th>
                                <th>총 회기 수</th>
                                <th>사용 회기 수</th>
                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of purchaseList" :key="index">
                                <td>{{item.centerName}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.consCount}}</td>
                                <td>{{item.consCountUse}}</td>
                                <td><span class="underline pointer" @click="client(item.idx)">상세정보</span></td>
                            </tr>
                            <tr v-if="purchaseList.length === 0">
                                <td colspan="5">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        value: '',
        purchaseList: [],
        centerList: [],
        idxCrmCompany: -1,
        idxCrmCenter: -1,
        name: '',

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
    }),

    mounted() {
        this.getCenterListAll()
        var oParams = GetUrlParams()
        this.idxCrmCompany = oParams.idxCrmCompany

        if(sessionStorage.getItem('origin_acceptanceclientb2b_pageNum') !== 'undefined' && sessionStorage.getItem('origin_acceptanceclientb2b_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_acceptanceclientb2b_pageNum'))
        }
        if(sessionStorage.getItem('origin_acceptanceclientb2b_value') !== 'undefined' && sessionStorage.getItem('origin_acceptanceclientb2b_value') !== null) {
            this.value = sessionStorage.getItem('origin_acceptanceclientb2b_value')
        }
        if(sessionStorage.getItem('origin_acceptanceclientb2b_center') !== 'undefined' && sessionStorage.getItem('origin_acceptanceclientb2b_center') !== null) {
            this.idxCrmCenter = sessionStorage.getItem('origin_acceptanceclientb2b_center')
        }

        this.getPurchaseClientList()
        this.getCompanyData(this.idxCrmCompany)
    },

    methods: {

        // 기업 정보 데이터 api 호출
        getCompanyData(idx) {
            var params = {
                idx: idx
            }

            this.axios.get(`/api/v1/company/${idx}`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.name = res.data.company.name
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    } else {
                        alert('센터리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if(err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 상세조회 페이지
        client(idx) {
            this.$router.push(`/origin/customer_manage_chart?idx=${idx}`)
        },
        // 수납등록 페이지로 이동
        register() {
            this.$router.push(`/origin/customer_acceptance_register_b2b?idxCrmCompany=${this.idxCrmCompany}`)
        },

        getPurchaseClientList() {

            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                value: this.value,
                idxCrmCenter: this.idxCrmCenter,
                idxCrmCompany: this.idxCrmCompany
            }

            sessionStorage.setItem('origin_acceptanceclientb2b_pageNum', this.pageNum)
            sessionStorage.setItem('origin_acceptanceclientb2b_value', this.value)
            sessionStorage.setItem('origin_acceptanceclientb2b_center', this.idxCrmCenter)

            this.axios.get('/api/v1/client/pur/user/list', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.purchaseList = res.data.purList
                        if (res.data.purListSize) {
                            this.listLength = res.data.purListSize
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.purchaseList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if(err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getPurchaseClientList()
            }
        },

        paging(index) {
            this.pageNum = index
            this.getPurchaseClientList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getPurchaseClientList()
            }
        },
    }
}
</script>
